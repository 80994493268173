@import "../../../app/colors.scss";
@import "../../../app/CommonStyles.scss";

.module-picker {
    background-color: $primary-color;
}

.item-row {
    cursor: pointer;
    margin: $keva-unit * 0.25;
    padding: $keva-unit * 0.25;
    background-color: lightblue;
    width: 100%;
}

.item-row.selected {
    background-color: lightgreen;
}
