.content {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}
