@import "../../app/CommonStyles.scss";
@import "../../app/tokens.scss";

.table {
    display: flex;
    align-items: flex-end;
    padding: $keva-unit;
    // background-color: rgb(186, 241, 172);
    width: -moz-fit-content;
    width: fit-content;

    table {
        width: 100%;
        // table-layout: fixed;
        overflow-wrap: break-word;

        td {
            // margin: $keva-unit;
            border: 2px solid lightgrey;
            padding: $keva-unit/2;
            text-align: center;
            background-color: $table-card-background-color;
        }
    }

    
}
