@import "../../../app/CommonStyles.scss";
@import "../../../app/tokens.scss";

.upcoming-calendar-events-row {
    background-color: $upcoming-card-background-color;
    margin-bottom: 4px;
}

.upcoming-calendar-event-header {
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    background-color: lightgrey;
}

.upcoming-calendar-event-header {
    @extend .upcoming-calendar-event-header;
    // background-color: rgb(191, 209, 186);
}

.activity-starttime {
    margin-left: 4px;
}

.box_content {
    // overflow-x: hidden;
}

.today {
    background-color: salmon;
    color: white;
    padding: 4px;
    box-sizing: border-box;
    // border: 2px solid grey;
}

.upcoming-calendar-event-row {
    @extend .upcoming-calendar-event-header;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left: 16px;
    background-color: $upcoming-card-content-background-color;
}

.upcoming-calendar-next-event-row {
    @extend .upcoming-calendar-event-header;
    height: 60px;
    justify-content: center;
    background-color: rgb(175, 230, 175);
}

.header-row {
    display: flex;
    justify-content: space-between;
}
