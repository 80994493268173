@import "../../app/CommonStyles.scss";

.modal-content {
    background-color: white;
    padding: $keva-unit;
    min-width: 200px;
    // max-width: 90%;
    width: 60%;
    height: 90%;
    // overflow-y: scroll;
    overflow-x: auto;
}
