@import "../../app/CommonStyles.scss";

.test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__page-links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &__page-link {
            margin: 8px;
        }
    }
}
