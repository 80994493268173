.activity-picker {
    height: fit-content;
    max-height: 50vw;
    width: 100%;
    // overflow-y: auto;

    .tree {
        overflow-y: auto;
    }
}
