@import "../app/CommonStyles.scss";
@import "../app/tokens.scss";

.subbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $keva-unit $keva-unit;
    margin-bottom: $keva-unit;
    color: white;
    width: 100%;
    height: 60px;
    background-color: $subbar-color;
    box-sizing: border-box;

    .subbar-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .lock-modules {
            color: red;
        }
    }
    .navitem {
        display: flex;
        margin-left: $keva-unit;
    }
}
