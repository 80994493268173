@import "../../app/colors.scss";

.module-creator {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // width: 100%;

    .select-label {
        margin-bottom: 8px;
    }

    .select {
        margin-bottom: 8px;
    }

    .input {
        margin-bottom: 8px;
    }
}
