@import "../../../app/CommonStyles.scss";

.duration-picker {
    display: flex;
    flex-direction: row;

    .row {
        display: flex;
        align-items: center;
        height: 40px;
    }

    &__labels {
        display: flex;
        flex-direction: column;
        margin-right: $keva-unit;

        label {
            @extend .row;
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;

        .number-stepper {
            @extend .row;
        }
    }
}
