$primary-color: #ebecf0;
$secondary-color: gray;
$tertiary-color: salmon;

$box-background-color: $secondary-color;

$current-task-background-color: $secondary-color;

$dashboard-background: white;

$calendar-background-color: rgb(36, 67, 80);
$calendar-weekday-label-color: white;
$calendar-weeknumber-color: white;
$calendar-card-background-color: rgb(145, 197, 145);
$calendar-card-background-color-weekend: rgb(106, 168, 77);
$calendar-card-background-color-outside-month: rgb(212, 223, 210);
$calendar-card-background-color-outside-month-weekend: rgb(166, 189, 165);
$calendar-card-background-color-red-day: rgb(80, 187, 94);
