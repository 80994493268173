$card-width: 200px;
$card-height: 50px;
$card-container-padding: 25px;

.card {
  display: flex;
  width: $card-width;
  height: $card-height;
  // border: 1px solid red;
  background-color: lightblue;
}

.cardContainer {
  width: $card-width /* + $card-container-padding */;
  height: $card-height;
}
