$global-background-color: rgb(236, 224, 224);

$topbar-color: rgb(133, 187, 79);
$subbar-color: rgb(167, 207, 127);
$body-background-color: white;

$module-background-color: $global-background-color;
// $card-front-background-color: /* $global-background-color */inherit;
$table-card-background-color: white;
$board-background-color:$global-background-color;

$box-content-background-color: $global-background-color;
$modal-box-background: white;

$upcoming-card-background-color: white;
$upcoming-card-content-background-color: white;

$table-card-background-color: white;