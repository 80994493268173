@import "../app/CommonStyles.scss";
@import "../app/colors.scss";

.main-responsive {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    // background: rgb(192, 214, 169);
    color: rgb(23, 43, 77);
    font-size: 14px;
}

.main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .top-bar {
        display: flex;
        padding-top: $keva-unit * 0.5;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
}
