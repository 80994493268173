$keva-unit: 16px;

$margin: $keva-unit;

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-top {
    display: flex;
    align-items: start;
}

.margin {
    &-top {
        margin-top: $margin;
    }
    &-right {
        margin-right: $margin;
    }
    &-bottom {
        margin-bottom: $margin;
    }
    &-left {
        margin-left: $margin;
    }
}
