@import "../../app/CommonStyles.scss";

.input-confirm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    input {
        margin-right: $keva-unit;
        min-width: 200px;
    }

    &-button {
        max-width: 200px;
        max-height: 21px;
    }
}

.input-confirm.label-on-top {
    flex-direction: column;
}