.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.label-left{
    flex-direction: row-reverse;
}

.label-top {
    flex-direction: column-reverse;
}

.label-bottom {
    flex-direction: column;
}