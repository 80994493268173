@import "../../../app/colors.scss";
@import "../../../app/CommonStyles.scss";
@import "../../../app/global.scss";
@import "../../../app/tokens.scss";

// .board .card {
//     background-color: $table-card-background-color;
// }

.card {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 8px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    // border: 1px solid black;
    border-radius: 10px;
    // background-color: rgb(238, 243, 233);
    // background-color: white;
    background-color: inherit;
    overflow: hidden;
    box-shadow: 1px 1px 1px 1px lightgrey;
    &-checkbox {
        width: 50px;
    }

    // background-color: $card-front-background-color;
    &-content {
        justify-content: flex-start;
    }

    &-container {
        // background-color: rgb(192, 214, 169);
    }

    .card-click-area{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
    }
}

.no-box .card {
    box-shadow: none;
}

.grid-item {
    height: 100%;
}

// .card-no-parent {
//     background-color: darkslategray;
//     color: white
// }

.no-box .card.highlighted {
    background-color: rgb(168, 226, 168);
}

.blocked {
    background-color: salmon;
}
