@import "../../../app/CommonStyles.scss";
@import "../../../app/tokens.scss";


.board-module {

    padding: $keva-unit;
    background-color: $global-background-color;

    .card {
        background-color: white;
    }
}