.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .tag {
        display: flex;
        flex-direction: row;

        border: 1px solid black;
        padding: 8px;
        border-radius: 16px;
        &:not(:last-child) {
            margin-right: 8px;
        }
        margin-bottom: 8px;
    }

    .selected {
        background-color: aqua;
    }
}
