@import "../../../app/colors.scss";
@import "../../../app/tokens.scss";

body {
  min-height: 100vw;
  margin: 0;
  background-color: $body-background-color;
}

.react-grid-layout-module {
  width: 100%;
}

.dev-helper {
  border: 1px dotted red;
  display: flex;
  justify-content: center;
  align-items: center;
}



.info {
  position: absolute;
  right: 0;
  top: 0;
  width: 120px;
  padding: 0 1rem;
  font-size: 0.75rem;
  border: 1px solid gray;
  background-color: rgba(0, 0, 0, 0.1);
}

ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

.invisible {
  display: none;
  background-color: red;
  width: 100px;
  height: 100px;
}
