@import "../../app/CommonStyles.scss";
@import "../../app/colors.scss";

$week-height: 15%;
$labels-height: 30px;

.calendar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $calendar-background-color;
    // padding: 5px;
    border-radius: 10px;
    color: black;
    width: 100%;
    // max-height: 250px;

    button {
        margin: 4px;
    }

    &-options {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: $calendar-weeknumber-color;
        height: 25px;
    }

    &-header {
        text-align: center;
        flex-direction: column;
        color: $calendar-weeknumber-color;
        height: 25px;
    }

    &-arrows {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $calendar-weeknumber-color;
        height: 30px;
    }

    &-month {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__header {
            color: white;
        }

        .schedule-link {
            display: flex;
            align-items: center;
            flex-direction: row;
            white-space: nowrap;
            width: 5%;
            margin-right: 8px;
            a {
                color: $calendar-weeknumber-color;
                font-size: large;
                font-weight: bolder;
                text-decoration: none;
                &:visited {
                    color: $calendar-weeknumber-color;
                }
            }
        }
    }

    &-week-container {
        display: flex;
        // flex-direction: column;
        // flex: 1;
        width: 100%;
        height: 19%;
        // &:first-of-type {
        //     height: calc(19% + 28px);
        //     @media only screen and (max-width: 768px) {
        //         height: auto;
        //     }
        // }

        &.hidden {
            display: none;
        }

        // @media only screen and (max-width: 768px) {
        //     height: auto;
        // }

        .calendar-week {
            display: flex;
            flex-direction: row;
            width: 100%;

            // &:first-of-type {
            //     height: calc(15% + 30px);
            // }

            &-days {
                display: flex;
                flex-direction: row;
                width: 100%;

                // @media only screen and (max-width: 768px) {
                //     flex-direction: column;
                // }

                .calendar-day {
                    display: flex;
                    flex-direction: column;
                    width: 14%;
                    margin: 0 3px 0 0;
                    overflow: hidden;

                    // @media only screen and (max-width: 768px) {
                    //     flex-direction: row;
                    //     width: 100%;
                    // }
                }
            }

            &-day {
                &-labels {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    font-size: x-large;
                    font-weight: bolder;
                    color: $calendar-weekday-label-color;
                    height: 30px;

                    .week-day-label {
                        display: flex;
                        width: 14.28%;
                        justify-content: center;
                        text-transform: capitalize;
                    }
                }
            }

            img {
                background-repeat: no-repeat;
                background-size: auto;
            }

            .highlighted {
                // background-color: lightgreen;
                border: 4px solid red;
            }

            .greyed-out {
                background-color: grey;
            }

            // @media only screen and (max-width: 768px) {
            //     flex-direction: column;
            // }

            .calendar-card {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                // height: 250px;
                margin: 2px;
                box-sizing: border-box;

                background-color: $calendar-card-background-color;
                border-radius: 5px;
                padding: 8px;
                height: 100%;
                overflow: hidden;

                .date {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    font-size: x-large;
                    font-weight: bolder;
                    text-transform: capitalize;
                    color: white;
                }

                .holiday {
                    display: flex;
                    word-break: break-all;
                    margin: 4px 0 12px 0;
                }

                .weather {
                    margin: 4px 0 12px 0;
                }

                &.helgdag {
                    background-color: $calendar-card-background-color-weekend;
                }

                &.röd-veckodag {
                    background-color: $calendar-card-background-color-red-day;
                    .date {
                        color: rgb(167, 13, 13);
                    }
                }

                &.date-outside-month {
                    background-color: $calendar-card-background-color-outside-month;
                    .date {
                        color: grey;
                    }
                }

                &.helgdag.date-outside-month {
                    background-color: $calendar-card-background-color-outside-month-weekend;
                }

                .content {
                    // @media only screen and (min-width: 768px) {
                    //     // display: -webkit-box;
                    //     // -webkit-line-clamp: 5;
                    //     // -webkit-box-orient: vertical;
                    //     // overflow: hidden;
                    //     margin: 4px 0 12px 0;
                    // }
                }

                .icons {
                    display: flex;
                    flex-direction: row;
                }

                .namnsdag {
                    display: flex;
                    flex-direction: row;
                    font-size: x-small;
                    margin: 4px 0 12px 0;
                }

                &.hover {
                    background-color: lightgray;
                }

                // @media only screen and (max-width: 768px) {
                //     display: flex;
                //     flex-direction: column;
                //     width: 100%;
                //     height: 150px;
                //     justify-content: center;
                //     align-items: center;
                //     font-size: x-large;

                //     .content {
                //         width: auto;
                //     }
                // }
            }
        }
    }

    .right-aligned {
        justify-content: flex-end;
    }

    .selected-button {
        border: 2px solid black;
    }

    // @media only screen and (max-width: 768px) {
    //     min-width: 0;
    //     width: 100%;
    //     height: auto;
    // }
}

.calendar-event-row {
    padding: $keva-unit;
    width: 100%;
    background-color: lightgray;
    margin-bottom: 2px;
}
